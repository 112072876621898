/* Use a grid to have the pager centered, and other elements automatically fill
the space to either side */
#myCarsRoot {
    display: grid;
    grid-template-columns: auto 170px auto;
    /* This variable controls the height of all boxes in the grid (counter,
    pager and panel) */
    grid-template-rows: 110px;
    gap: 10px;
    padding: 10px;
}

/* Make all children occupy the full height, so boxes are uniform heights */
#myCarsRoot > div {
    height: 100%;
}

/* Container for title and counter, relative pos so children can be absolute */
#myCarsLeft {
    position: relative;
    height: 100%;
}

/* Title is left-justified */
#myCarsTitle {
    position: absolute;
    left: 0px;
}

/* Counter will expand to ooccupy the right half of myCarsLeft */
#myCarsCounter {
    position: absolute;
    right: 0px;
    width: 180px;
    height: 100%;
}

/* Element returned by MyCarCounter.render() will occupy parent's full size */
#myCarsCounter > * {
    width: 100%;
    height: 100%;
}

#myCarsCounter table {
    margin-left: 5px;
    margin-right: 5px;
}

/* Overwrite App.css rule td { text-align: center !important; } */
#myCarsCounter td:first-child {
    text-align: left !important;
    padding: 5px;
}

#myCarsCounter td:last-child {
    text-align: right !important;
}

.carCounterGrid > .row > div:first-child {
    padding-left: 5px;
    padding-right: 0px;
}

.carCounterGrid > .row > div:last-child {
    padding-left: 0px;
    padding-right: 5px;
}

.carCounterGrid > .row {
    padding-top: 5px;
    padding-bottom: 5px;
}

/* Element returned by MyCarPager will have same height as other boxes */
#myCarsPager > * {
    height: 100%;
}

/* space out "PAGE" text */
#myCarsPager > div > div {
    padding-top: 20px;
    margin-bottom: 15px;
    font-size: 20px;
}

.highlight {
    background-color: yellow;
}
