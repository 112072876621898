/* profileTable: used for compact tables in profile page and user_detail page.
 * Those tables also use the `size="sm"` to get some of their styling.
*/
.profileTable {
    margin-bottom: 0 !important;
}
.profileTable th {
    border-right: 1px solid #dee2e6;
    text-align: center;
}
.profileTable tbody tr:nth-child(even) {
    background-color: #ffffff;
}
.profileTable tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
}
.profileTable td {
    border-right: 1px solid #dee2e6;
}
.profileTable th:last-child, .profileTable td:last-child {
    border-right: 0;
}
