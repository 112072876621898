.userDocumentRow > td {
    padding: 0px !important;
}

.userDocRowButton {
    margin: 2px !important;
}

/* Make as narrow as possible, the timestamps within the column will extend it
to a minimum width */
.userTimeCol {
    width: 90px;
}

.userInfoCol {
    width: 90px;
}

.userOptionsCol {
    width: 90px;
}
