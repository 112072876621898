.taskRow {
    --cell-height: 85px;
    --cell-padding: 0.3rem;
    --cell-border: 1px;
}

.taskRow td {
    padding: 0px;
    height: var(--cell-height);
    border: var(--cell-border);
}

.taskRow td a {
    width: 100%;
    height: 100%;
}

.taskRow td a div.innerCellDiv {
    /* use padding here instead of the <td> so the padding is part of the
    clickable link to the car */
    padding: var(--cell-padding);
    border: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;

    /* center the contents of each table cell */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* If a cell has an image in it, make it fit perfectly */
.taskRow td img {
    height: calc(
        var(--cell-height)
        - 2 * var(--cell-padding)
        - var(--cell-border)
    );
}
