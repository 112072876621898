.Navbar {
    background-color: transparent !important;
}

#MainNav {
    padding: 0px;
}

.Navbar_Buttons{
    background-color: white !important;
    padding:0px !important;

}

.LinkButtons{
    align-content: center !important;
    justify-content: center;
    align-items: center;
    padding: 10px !important;
    display: flex !important;
}

/* Navbar active lit up green */
#ButtonContainer > a.active{
    background-color: rgb(163,250,157) !important;
    justify-content: center;
    align-items: center;
}

.nav-table td{
    text-align: right !important;
    padding-right: 12px;

}

.tinyText {
    font-size: 10px;
}

/* Changed this to a class so it can be reused for the Nav with "Logout". */
.navButtonContainer {
    /* has to be taller than the table at the end (105px as of 2024-04-10) */
    min-height: 105px;
    padding-bottom: 0px !important;
    /* note: for "Home", "Auction Search", etc. the width is calculated in
    navbar.js b/c it depends on number of items (admins get an extra button) */
}

/* The Container Row that contains all of the items in the NavBar */
.navbar-row {
    width: 100%;
    /* For some reason, the div isn't aligned with its parent so the buttons
    can't take up the full width. This fixes it */
    position: relative;
    left: 15px;
}

/* For the columns within .navbar-row, get rid of horizontal padding to make
them go up to the edges and allow the viewport to shrink more before the row
collapses */
.navbar-row > div {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

/* Make the clickable region similar to the neighboring buttons. Due to the
padding of the parent div, it isn't quite the same. But removing the padding
stops the width from being the same as the other buttons. */
.AdminDropdown > a {
    height: 100%;
    align-content: center !important;
    justify-content: center;
    align-items: center;
}
