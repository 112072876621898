.myCarsList {
    overflow: auto;
    border: 0 !important;
    border-radius: 0 !important;
    border-collapse: collapse;
}

.myCarsListItem {
    padding: 0px 5px !important;
    border-radius: 0 !important;
    border: 0 !important;
    border-right: 1px solid #e6e6e6 !important;
    margin: 5px 0px;
    width: 180px !important;
}

.myCarsListItem:last-child {
    border-right: 0 !important;
}

.myCarsImage {
    width: 170px;
    display: block;
}
