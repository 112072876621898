/* DashWidget.css
 * Style shared by multiple widgets on the dashboard page
*/

:root {
    --numDisplayedItems: 5;
}

.widgetList {
    height: calc(var(--numDisplayedItems) * 3em + 0.5em);
    overflow: auto;
}

.widgetListItem {
    padding: 0.7em;
    margin: 0px;
}

.severityReminder {
    background-color: #FF4 !important;
    border-radius: 4px;
}
