.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}


/* Blue for all backgrounds*/
.bg-primary{
  background-color: rgb(108,150,203)
}

body{
  background-color: rgb(108,150,203) !important;
}

/* For easy access to drop shadows */
.dropShadow{
  box-shadow: 4px 4px 2px rgb(73, 97, 128);
}

/* Tools to make the white card-like design */
.whiteBackground {
  background-color: white;
}
.wideContainer {
  max-width: 98% !important;
  padding: 0px !important;
}

.blueBorderParentContainer{
  padding: 5px !important;
}
.blueBorderContainer{
  border-color:  rgb(156,204,255);
  border-style: solid;
  border-width: 5px;
  margin-bottom: 0px;
  padding: 4px;
}
.italicTitle{
  font-style: italic;
  font-size:larger;
  font-weight: bold;
}
.greyText{
  color: rgb(128,128,128);
}
.whiteTitle{
  color:white;
  font-style: italic;
  font-size:3em;
  font-weight: bold;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
}
.smallWhiteTitle{
  color:white;
  font-style: italic;
  font-size:1em;
  font-weight: bold;
}
.blackTitle{
  color:black;
  font-size:1em;
  font-weight: bold;
}
/* same as white title... but grey! 
   used in request queues page
*/
.greyTitle{
  color:rgb(128,128,128);;
  font-style: italic;
  font-size:3em;
  font-weight: bold;
}
.minorTitle{
  font-weight: bold;
  align-items: center;
}
/* Dashboard cards internal spacing */
.cardSpace{
  margin-bottom: 15px;
  border-radius: unset !important;
  padding: 0px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#react-file-drop {
  border: 1px solid black !important;
  width: 600 !important;
  height: 100px;
  color: black !important;
  padding: 20  !important;
  text-align: center;

}

.whitespace {
  white-space: pre-line;
}
/* use the table version on the <table> tag for full table - TD forces center. Some stuff should be left-ed*/
.whitespaceLeft {
  white-space: pre-line;
  text-align: left !important;
}
/* Left align a whole danged table */
table.leftAlignTable td{
  white-space: pre-line;
  text-align: left !important;
}

.redtext {
  color: red;
}

/* for highlighting the background colour for auction times */
.auctionPassed {
  background-color: lightcoral !important;
}
.auctionNear {
  background-color: lightyellow !important;
}
.auctionOK {
  background-color: lightgreen !important;
}
.auctionTimeNotSet {
  background-color: white !important;
}

/* For making whole cells clickable in a table.*/
a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
  display: block; 
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
  display: block; 
}

.rightAlignTextbox {
  text-align:right;
}

/* vertical align centre */
.alignCentre{
  align-items: center;
}

.alignCentreCol{
  margin-top: auto;
  margin-bottom: auto;
}

.alignColVertical{
  display: flex;
  align-items: center;
}

/* make card inner text wall-to-wall */
.flush_card{
  padding-left: 0px !important;
  padding-right: 0px  !important;
}
/* translation report headings */

.translationheading {
  text-decoration: underline;
  font-weight:bold;
}

.imageColumn {
  width: 154px;
}
/* for setting result set column width - bootstrap switches to sm at 576 */
@media screen and (max-width: 576px) {
  .imageColumn {
    width: 71px;
  }
}
/* for react-image-gallery */
@import "~react-image-gallery/styles/css/image-gallery.css";
.reactGallery{
  width: 100%;
  max-height: 700; /*height of an auction sheet*/
}

/* Turn off form highlighting trash */
input, textarea, select, .form-control, .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

/* for dropdown headers
Kinda reinvents the cards, but I didn't like how they looked
also show a hand, to make it clickable.
*/
.greyBox {
  border-color: rgb(217, 222 , 226);
  border: 1px solid #dee2e6;
  background-color: rgb(242, 242, 242);
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

/* Show a hand over a div */
.theHand {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

/* Wide modal */
.modal-90w {
  width: 90%;
  max-width: none!important
}

/* stretch an auction sheet to fill the container it is in */
.auctionSheetStretch{
  width: 100% !important; 
}

.crossedOutText {
  text-decoration: line-through;
}

/* ensure there are lines between listgroup items */
.list-group-item-line {
  border: 1px solid rgba(0,0,0,.125) !important;
}


/* overriding button styling */
.btn{
  border-radius: unset !important;
  font-style: italic;
  color:black !important;
  font-weight: bold !important;
}
.btn-primary{
  background-color: rgb(217, 217,217) !important;
}
.btn-secondary{
  background-color: rgb(164, 252, 158) !important;
}

/* new striped table styles, over rides the bootstrap one*/
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255,255,255,1) !important;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(224,234,247,1) !important;
}
.table-bordered thead td, .table-bordered thead th {
  border-width: 2px !important;
  background-color: rgb(162,192,228) !important;
  border-color: black !important;
  /* Doesn't need to be important, overrides Reboot's <th>/<td> styles due to
     having higher specificity */
  text-align: center;
  vertical-align: middle !important;
}
td {
  /* Doesn't need to be important, only thing it's overriding is Reboot's
     text-align: left applied to the <body> */
  text-align: center;
  /* This does need to be important, Bootstrap's table styles would override
     it with vertical-align: top */
  vertical-align: middle !important;
}
/* Class for left-aligning text in a table, overriding the above <td> style */
.left-align-table td {
  text-align: left;
}

.preview{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}