
.Navbar {
    background-color: transparent !important;
}


/* Normal buttons are blue */
#DashNavContainer > a{
    background-color: rgb(78,171,244) !important;
    justify-content: center;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
    color: white !important;
    padding: 5px;
    min-width: 90px;
}

/* Navbar active lit up dark blue */
#DashNavContainer > a.active{
    background-color: rgb(45,105,149) !important;
    justify-content: center;
}

#DashNavContainer {
    /* has to be taller than the table at the end */
    text-align: center;
}