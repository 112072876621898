/* To avoid inner text expanding the size of this element, we make its position
relative and its children absolute. */
#myCarsPanel {
    max-width: 495px;
    height: 100%;
    position: relative;
}

#myCarsFilters {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 5px;
    display: grid;
    grid-template-columns: auto auto auto;
}

#myCarsInputTable {
    position: absolute;
    left: 5px;
    bottom: 0px;
}

#myCarsInputTable td {
    padding: 5px;
}

#fromFieldTD, #toFieldTD {
    max-width: 150px;
}

#fromField, #toField, #seachFieldTD, #searchField {
    width: 100%;
}
