/* Make the modal fill as much of the page as possible */
.translation-dialog {
    width: 95% !important;
    /* don't want the columns to become too wide, because the images become
    too large and don't fit on the page */
    max-width: 1100px !important;

    --vertical-margin: 1rem;
    margin-top: var(--vertical-margin) !important;
    margin-bottom: var(--vertical-margin) !important;
    height: calc(100vh - 2 * var(--vertical-margin)) !important;
}

/* Set fixed heights for the header, body and footer, so we can use
overflow: auto */
.translation-dialog > .modal-content {
    height: 100% !important;

    --header-height: 64px;
    --footer-height: 64px;
    --body-height: calc(100% - var(--header-height) - var(--footer-height));

    & .modal-header {
        height: var(--header-height);
    }
    & .modal-footer {
        height: var(--footer-height);

        & button {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}

/* Give the body a fixed size so one of the columns within it can use
overflow: auto */
.translation-modal-body {
    height: var(--body-height);
    /* The modal body will have the notices at the top, followed by everything
    else. Define the height of the notices here so the other part of the body
    can take up the remaining space. */
    --notices-height: 120px;
}

/* Notices at the top (alerts and list of requesters) needs to be fixed height,
so the rest of the modal can have its height set to occupt the remaining space,
which is needed to make the right column scrollable. */
.translation-notices {
    height: var(--notices-height);
}

/* To ensure it fits within the fixed height of translation-notices, cut off
the list of requesters if it is longer than two rows of text. */
.requested-by-list {
    /* https://stackoverflow.com/questions/20594995/why-webkit-line-clamping-does-not-work-in-firefox/20595073#20595073 */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    /* defining a regular max height in addition to the line-clamp will ensure
    this works on older browsers too, just without the ellpisis */
    max-height: calc(2 * var(--bs-body-line-height));
}

/* Make everything else (image gallery, form controls, etc) take up the rest of
the modal body's height... */
.translation-fields {
    height: calc(100% - var(--notices-height));
}
/* ...then make sure the row which the two columns are in also uses the max
height... */
.translation-fields > div.row {
    height: 100%;
}
/* ...then make sure both columns take up the max height, and have a scrollbar
if they don't fit. */
.translation-fields > div.row > div {
    height: 100%;
    overflow-y: auto;
}

/* Put space below each large textarea, so it's clear they correspond to the
labels above them them, not below */
.translation-modal-body .large-translation-fields > div {
    margin-bottom: 1em;
}

.translation-modal-body .attribute-row {
    margin: 1px;
    /* Vertically center labels and controls */
    align-items: center;
    /* Remove padding from first and last columns since they're very narrow */
    & div:first-child {
        padding: 0px;
    }
    & label:first-child {
        padding: 0px;
    }
    & div:last-child {
        padding: 0px;
    }
}
