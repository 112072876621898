.searchContainer {
    padding-top: 10px;
    padding-bottom: 10px;

    /* Reduce gaps between columns by using +/-10px instead of the default
    +/-15px for column padding and row margins. */
    --col-h-padding: 10px;
    --row-h-margin: -10px;
}

/* Space the rows out a tiny bit with margin-bottom. */
.searchFields div.row {
    margin-bottom: 3px;
    margin-left: var(--row-h-margin);
    margin-right: var(--row-h-margin);
}

/* Prevent rows within rows from having the margin applied, which would create
an inconsistent vertical gap between rows. */
.searchFields > div.row div.row {
    margin-bottom: 0px;
}

/* Vertically center labels */
.searchFields div.row > div {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: var(--col-h-padding);
    padding-right: var(--col-h-padding);
}

.blueBorderContainer.spaceBottom {
    margin-bottom: 10px;
}
