:root {
    /* The amount of left padding applied by react bootstrap */
    --radio-left-pad: 20px;
    /* The amount of left margin we'll add to the answers */
    --radio-left-margin: 1em;
    /* The total width of the card */
    --card-width: 40rem;
    /* Padding applied by react bootstrap */
    --card-padding: 20px;
    /* Subtract from width of text fields so they line up with radio button labels */
    --text-input-hmargin: calc(2 * (var(--card-padding) + var(--radio-left-margin) + var(--radio-left-pad)));
}

.quizRadio {
    font-size: 1.1em;
    margin-left: var(--radio-left-margin);
    color: rgb(61, 155, 233);
}

.quizRadio > input:hover, .quizRadio > input:focus {
    background-color: rgb(158.5, 205.5, 244.5);
}

.quizCard {
    width: var(--card-width);
    margin-left: auto;
    margin-right: auto;
}

.quizTitle {
    text-align: center;
    margin-top: 10px;
    font-size: 1.75rem !important;
}

.quizTextInput {
    min-width: calc(100% - var(--text-input-hmargin));
    margin-left: auto;
    margin-right: auto;
    border: 0px !important;
    border-radius: 0px !important;
    border-bottom: 3px solid rgb(61, 155, 233) !important;
}

.quizTextInput:hover {
    border-bottom: 2px solid black !important;
}

.successCard {
    max-width: 250px;
    text-align: center;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: #DFD !important;
    border-color: #5A5 !important;
}

.successCard > .card-body {
    padding-top: 0px;
}

.helpContainer {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.helpBox {
    font-size: 0.8em;
    background-color: #FFFFF4;
    border: 3px dashed #FD0;
    border-radius: 10px;
    /* extra top padding for "HELP" label */
    padding: 0.75em 0.6em 0.6em;
    color:rgb(61, 155, 233);
    box-shadow: 0px 0px 4px rgba(73, 97, 128, 0.5);
}

.helpLabel {
    position: absolute;
    top: -10px;
    left: -10px;
    color: red;
    transform: rotate(-10deg);
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 1.25em;
}
