/* Make the Alerts widget always the same size (we want --numDisplayedItems
alerts to be visible at once) */
:root {
    --numDisplayedItems: 6;
}

/* For the widget's ListGroup, uses a fixed size */
.alertList {
    /* Use a scroll bar if alerts don't fit in the fixed-size container */
    overflow: auto;
    /* Each contained ListItem was found to be 2.55em through trial and error */
    height: calc(var(--numDisplayedItems) * 2.55em) !important;
}

/* Make alert widget items compact by removing margins and reducing padding */
.alertListItem {
    padding: 0.5em !important;
    margin: 0px !important;
}
