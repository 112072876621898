.container.staff-sales-main {
    padding: 10px 0px;
    max-width: 1400px;
}

.staff-sales-error .octicon {
    position: relative;
    top: -2px;
    fill: #c00 !important;
}

.container.staff-sales-container {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 1400px;
}

/* The charts are placed before the table, but the horizontal direction is
reversed. The effect is that on big screens, the table is to the left of the
charts, and on small screens, the charts are above the table. */
.staff-sales-container > .row {
    flex-direction: row-reverse;
}

.sales-report-controls.row {
    margin-left: auto;
    margin-right: auto;
}

.sales-report-controls.row > div {
    padding-left: 5px;
    padding-right: 5px;
}

/* Make the text between the <input> boxes line up better */
.sales-report-controls.row > div:nth-child(odd)
{
    padding-top: 8px;
}

/* Size down the Datetime so there's room for the Button beside it */
.sales-report-date-select > .rdt {
    width: calc(100% - 30px);
}

/* InputGroup sets the border radii for the div but not the underlying input */
.sales-report-date-select > .rdt > input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

/* InputGroup should be setting these border radii but something overrides it */
.sales-report-date-select > *:last-child > button {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

/* Users can click the <th> cells to change the sort method */
.sales-table th {
    cursor: pointer;
}

/* Make the Octicons line up better with the text */
.sales-table th .table-sort-icon {
    position: relative;
    top: -2px;
}

.sales-table-pager {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.sales-table-pager > div {
    padding-left: 5px;
    padding-right: 5px;
}

.col.sales-chart-col {
    padding: 0px;
}

div.staff-sales-chart {
    min-width: 360px;
    width: 100%;
    height: 100%;
}

div.staff-sales-pie-chart {
    min-width: 165px;
    max-height: 200px;
    width: 100%;
    height: 100%;
}

div.staff-sales-pie-chart > svg {
    width: 100%;
    height: 100%;
}

.per-page-button {
    padding: 1px 3px !important;
}
