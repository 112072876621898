
.page-item {
    background-color: inherit;
    color: white  !important;
    border:unset;
}

.page-item > .disabled {
    color: white !important;
    background-color: inherit;
    border: unset;
}

.page-link{
    border: 0px;
    color: white !important;
    background-color: rgb(140,191,255) !important;;
    border: 0 !important;
}

.pager-middle{
    display: flex;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
}

.pagination{
    border: unset;
}

.pagination > li > a
{
    background-color: rgb(140,191,255);
    color: white;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover
{
    color: inherit;
    background-color: inherit;
    border-color: unset;
}

.pagination > .active > a,
.pagination > .disabled > a
{
    color: inherit;
    background-color: inherit;
    border: unset;
}

.pagination > .active > a:hover
{
    background-color: inherit;
    border: unset;
}