body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.around_container {
  padding-top: 15px;
  padding-bottom: 15px;
}

/* form for the login page*/
/*.form-horizontal {
  width: 100%;

}

.form-group {
  width: 50%;
}

.input-group {
  width: 90% !important;
}

.form-control {
  width: 50% !important;
}

span.input-group-addon {
  width: 50px !important;
}*/