.date-range-picker.row > div {
    padding-left: 5px;
    padding-right: 5px;
}

/* Make the text between the <input> boxes line up better */
.date-range-picker.row > div:nth-child(odd)
{
    padding-top: 8px;
}

/* Size down the Datetime so there's room for the Button beside it */
.date-range-picker-select > .rdt {
    width: calc(100% - 30px);
}

/* InputGroup sets the border radii for the div but not the underlying input */
.date-range-picker-select > .rdt > input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

/* InputGroup should be setting these border radii but something overrides it */
.date-range-picker-select > *:last-child > button {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
