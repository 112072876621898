.documentRow > td {
    padding: 0px !important;
}

.docRowButton {
    margin: 2px !important;
}

.timeCol {
    width: 90px;
}

.flagsCol {
    width: 135px;
}

.optionsCol {
    width: 155px;
}

/* When a document is dragged over the target, change card header text color */
.docUploader > .file-drop-target.file-drop-dragging-over-target {
    color: #5AF;
}
