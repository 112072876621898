.print-car-root {
    width: 100%;
}

.print-car-header {
    width: 100%;
    padding-bottom: 5px;
}
.print-car-header > img {
    width: 100%;
}

/*
Layout: two columns. Left column is 2/3rds of the page, and has the auction
sheet at full size, with small versions of all the other images below it.
Right column is the translation table (if applicable) and the car details table.
*/
.print-car-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 66vw 33vw;
    column-gap: 1vw;
    margin-left: auto;
    margin-right: auto;
}

.images-column > .auction-sheet {
    width: 100%;
}

/* Small images below auction sheet, in multiple rows if enough images exist */
.other-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.other-images > div {
    height: 150px;
    padding: 5px;
}
.other-images > div > img {
    height: 100%;
}

.translation-table {
    width: 100%;
    margin-bottom: 10px;
}
.translation-table td {
    border-color: rgb(222, 226, 230);
    border-width: 1px;
}
