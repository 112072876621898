.pcaFooter {
    width: 100%;
    margin: 20px 0px;
    padding: 0px;
    border: 0px;
}

.pcaFooter > a {
    display: block;
    width: 24rem;
    height: 100%;

    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    
    padding: 0px;
    border: 0px;

    text-align: center;
    color: #e8e8e8;
    text-shadow: 1px 1px 1px black;
    font-weight: 500;
}

.pcaFooter > a:hover {
    color: #fff;
    text-decoration: none;
}
