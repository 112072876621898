.queueBox {
    height: 400px;
    overflow: auto;
    cursor: pointer; /* Since we unset all the link properties below */
}

/* Prevent the entire box from having hyperlink style applied to it */
.queueBoxLink, .queueBoxLink:hover {
    all: unset;
}

/* Design document shows the vertical space between boxes being the same as the
horizontal space. So for boxes on the left we'll give them half of their usual
right padding, and vice-versa, so the combined gap is the usual width. */
.leftQueueBox {
    padding-right: 7.5px !important;
}

.rightQueueBox {
    padding-left: 7.5px !important;
}
