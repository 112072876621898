.clearButton {
    border-radius: 5px !important;
    height: 100%;
    width: 100%;
}

.clearButton:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.xIcon {
    fill: red !important;
}
